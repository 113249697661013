.navigation {
    $elm: &;
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    opacity: 0;
    transition: $transition-default;

    @include media-breakpoint-down(sm) {
        overflow-y: scroll;
    }

    &--active {
        opacity: 1;
    }

    @include media-breakpoint-up(md) {
        padding: 0;
        opacity: 1;
        height: auto;
        display: inline-block;
        flex-wrap: unset;
        position: static;
        width: auto;
    }

    @include media-breakpoint-up(xl) {
        margin-left: 1rem;
    }

    &__wrapper {
        transition: all ease-in-out 0s 1s;
        height: 100%;
        overflow: hidden;
        display: flex;
        position: static;
        pointer-events: none;

        @include media-breakpoint-up(md) {
            height: auto;
            overflow: visible;
            pointer-events: initial;
        }

        &--active {
            pointer-events: initial;

            @include media-breakpoint-up(md) {
                margin-top: -#{map-get($header-height, "small")};
                padding-top: #{map-get($header-height, "small")};
            }
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__list {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    &__contact {
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        display: block;
        padding: .5rem 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            padding: 0;
            display: inline-block;

            &--has-sub:hover {
                #{$elm}__list--sub {
                    visibility: visible;
                    opacity: 1;
                    pointer-events: initial;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            margin: 0 .5rem;
        }
    }

    &__item {
        display: inline-block;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.5rem;
        padding: .5rem;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
            text-align: left;
            height: map_get($header-height, "medium");
            display: inline-flex;
            align-items: center;

            &--has-sub {
                &:hover {
                    #{$elm}__list--sub {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: initial;
                        transition-delay: 0s;
                    }
                }
            }
        }

        @include media-breakpoint-up(xl) {
            font-size: 1.125rem;
        }

        @include media-breakpoint-up(xl) {
            padding: .5rem .75rem;
        }

        &:hover {
            text-decoration: none;
            color: map-deep-get($colors, "brand", "secondary");
        }

        &--active {
            color: map-deep-get($colors, "brand", "secondary");

            svg {
                fill: map-deep-get($colors, "brand", "secondary");
            }
        }
    }

    &__list--sub {
        display: block;
        position: absolute;

        #{$elm}__list-item {
            display: block;
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            top: 100%;
            left: 0;
            visibility: hidden;
            transition: $transition-default;
            pointer-events: none;
            opacity: 0;
            transition-delay: .2s;
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &__back {
        position: fixed;
        left: 2rem;
        top: 3.5rem;
        transform: translate3d(0, 0, 0);

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
