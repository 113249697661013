﻿.sub-level-0 {
    $elm: &;
    padding-top: map_get($header-height, "medium--scrolled");

    &__item-icon {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    // DESKTOP
    @include media-breakpoint-up(md) {
        padding-top: 0;

        &__item-icon {
            display: inline-block;

            svg {
                height: 1rem;
                width: 1rem;
                fill: map-deep-get($colors, "brand", "primary");
                margin-left: .5rem;
                transition: $transition-default;
            }
        }

        #{$elm}__item {
            &:hover {
                #{$elm}__item-icon {
                    svg {
                        fill: map-deep-get($colors, "brand", "secondary");
                    }
                }
            }
        }

        &--inverted {
            #{$elm}__item, .nav-link.nav-level__l0 {
                color: map-deep-get($colors, "white", "default");
                text-shadow: $shadow-text;

                &:hover {
                    color: map-deep-get($colors, "brand", "secondary");
                }
            }

            #{$elm}__item-icon {
                svg {
                    fill: map-deep-get($colors, "white", "default");
                }
            }
        }

        &--scrolled {
            #{$elm}__item-icon {
                svg {
                    fill: map-deep-get($colors, "brand", "primary");
                }
            }
        }
    }

    &--scrolled {
        #{$elm}__item, .nav-link.nav-level__l0 {
            color: map-deep-get($colors, "brand", "primary");
            text-shadow: none;

            @include media-breakpoint-up(md) {
                height: map_get($header-height, "medium--scrolled");
            }

            &:hover {
                color: map-deep-get($colors, "brand", "secondary");
            }
        }
    }
}
