﻿.article-cta {
    background: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "white", "default");
    padding: 2rem;
    margin-top: 1.5rem;
    border-radius: $border-radius;
    box-shadow: $shadow-default;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        padding: 3rem;
    }

    &__title, &__paragraph {
        color: map-deep-get($colors, "white", "default");
    }
}