﻿p {
    color: map-deep-get($colors, "brand", "primary");
    line-height: 1.5;
    margin: 0;
    margin-bottom: 1.5rem;
}

.intro {
    font-size: 1.25rem;
    line-height: 1.25;
}

.label {
    font-weight: 700;
    font-size: .875rem;
    line-height: 1rem;
    text-transform: uppercase;
}
/* stylelint-disable */
.dark {
    p, h1, h2, h3, h4, h5, h6 {
        color: map-deep-get($colors, "white", "default");
    }
}
/* stylelint-enable */

strong {
    font-weight: 700;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6  {
    color: map-deep-get($colors, "brand", "primary");
    font-family: $font-family-headings;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    margin-bottom: 1.5rem;
}

h1, .h1 {
    font-size: 2rem;

    @include media-breakpoint-up(sm) {
        font-size: 2.5rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 2.875rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 3.75rem;
    }
}

h2, .h2 {
    font-size: 1.75rem;

    @include media-breakpoint-up(sm) {
        font-size: 2rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 2.5rem;
    }
}

h3, .h3 {
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }
}

h4, .h4 {
    font-size: 1.25rem;

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }
}

h5, .h5 {
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
    }
}

h6, .h6 {
    font-size: 1rem;
}