﻿.article-card {
    padding-bottom: 20px;
    $elm: &;

    &__inner {
        border-radius: $border-radius;
        box-shadow: $shadow-default;
        display: block;
        padding-top: 66.667%;
        position: relative;
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            padding-top: 56.25%;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 75%;
        }

        &:hover {
            box-shadow: $shadow-hover;

            #{$elm}__content {
                &::before {
                    opacity: .85;
                }
            }

            #{$elm}__title, #{$elm}__paragraph {
                color: map-deep-get($colors, "white", "default");
            }

            #{$elm}__textlink {
                opacity: 1;
                margin-top: 0;
                max-height: 3rem;
                transform: translateX(0);
            }

            #{$elm}__paragraph {
                opacity: 1;
                margin-top: 0;
                max-height: 3rem;
                line-height: 1.5;
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 1.5rem 1.5rem;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            padding: 0 2rem 2rem;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: $border-radius;
            background: map-deep-get($colors, "brand", "primary");
            opacity: 0.2;
            transition: $transition-default;
        }
    }

    &__title {
        word-break: break-word;
        hyphens: auto;
        hyphenate-limit-chars: 6 3 2;
        font-size: 2rem;

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 2rem;
        }
    }

    &__title, &__paragraph, &__date {
        color: map-deep-get($colors, "white", "default");
        text-shadow: $shadow-text;
        margin-bottom: 0;
        position: relative;
        transition: $transition-default;
    }

    &__date {
        margin-bottom: .5rem;
    }

    &__paragraph {
        line-height: 0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: $transition-default;
        display: none;

        @include media-breakpoint-up(xl) {
            display: block;
        }
    }

    &__textlink {
        color: map-deep-get($colors, "brand", "tertiary");
        max-height: 0;
        opacity: 0;
        transform: translateX(50px);
        transition: $transition-default;

        svg {
            fill: map-deep-get($colors, "brand", "tertiary");
        }

        &:hover {
            color: map-deep-get($colors, "brand", "tertiary");

            svg {
                fill: map-deep-get($colors, "brand", "tertiary");
            }
        }
    }

    &--medium {
        #{$elm}__inner {
            padding-top: 75%;
            position: relative;

            @include media-breakpoint-up(sm) {
                padding-top: 50%;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 75%;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 50%;
            }
        }
    }

    &--news {
        #{$elm}__paragraph {
            display: none;
        }
    }

    &--medium-news {
        #{$elm}__inner {
            padding-top: 100%;
            position: relative;

            @include media-breakpoint-up(sm) {
                padding-top: 66.667%;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 75%;
            }
        }
    }

    &--large {
        @include media-breakpoint-up(lg) {
            height: 100%;
        }

        #{$elm}__inner {
            padding-top: 100%;

            @include media-breakpoint-up(sm) {
                padding-top: 75%;
            }

            @include media-breakpoint-up(lg) {
                height: 100%;
                padding-top: 66.667%;
            }

            &:hover {
                #{$elm}__paragraph {
                    max-height: 4.5rem;
                }
            }
        }

        #{$elm}__title {
            font-size: 2rem;

            @include media-breakpoint-up(md) {
                font-size: 2.5rem;
            }
        }

        #{$elm}__content {
            @include media-breakpoint-up(md) {
                padding: 2rem 3rem;
            }
        }

        #{$elm}__paragraph {
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }

    &--alt {
        #{$elm}__inner {
            padding-top: 100%;
            position: relative;

            @include media-breakpoint-up(sm) {
                padding-top: 75%;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 66.667%;
            }
        }
    }

    &--alt-news {
        #{$elm}__inner {
            padding-top: 100%;
            position: relative;

            @include media-breakpoint-up(sm) {
                padding-top: 75%;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 100%;
            }
        }
    }

    &--blue {
        #{$elm}__inner {
            background: map-deep-get($colors, "brand", "primary");
        }
    }

    &--green {
        #{$elm}__inner {
            background: map-deep-get($colors, "brand", "secondary");
        }
    }

    &--yellow {
        #{$elm}__inner {
            background: map-deep-get($colors, "brand", "tertiary");

            &:hover {
                #{$elm}__paragraph, #{$elm}__date {
                    color: map-deep-get($colors, "brand", "primary");
                }
            }
        }

        #{$elm}__paragraph, #{$elm}__date {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &--noimg {
        #{$elm}__title, #{$elm}__paragraph, #{$elm}__date {
            text-shadow: none;
        }

        #{$elm}__content {
            &::before {
                content: none;
            }
        }

        &#{$elm}--yellow {
            #{$elm}__textlink {
                color: map-deep-get($colors, "brand", "primary");

                svg {
                    fill: map-deep-get($colors, "brand", "primary");
                }

                &:hover {
                    color: map-deep-get($colors, "brand", "primary");

                    svg {
                        fill: map-deep-get($colors, "brand", "primary");
                    }
                }
            }
        }
    }
}
