﻿.breadcrumbs {
    font-size: .875rem;
    text-transform: uppercase;
    position: relative;

    &__list-item {
        display: inline-flex;
        align-items: center;
        opacity: 1;
    }

    &__link {
        opacity: .5;

        &:hover {
            opacity: 1;
            text-decoration: none;
            color: map-deep-get($colors, "brand", "secondary");
        }
    }

    &__divider {
        margin: 0 .25rem;
        display: flex;

        svg {
            height: .875rem;
            width: .875rem;
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }
}