﻿.search-card {
    height: 100%;
    display: block;
    padding-bottom: 20px;
    $elm: &;

    &__flex {
        display: flex;
        height: 100%;
        border-radius: $border-radius;
        background: map-deep-get($colors, "white", "default");
        padding: 1.5rem;
        flex-direction: column;
        box-shadow: $shadow-default;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            padding: 2rem;
        }
    }

    &__title {
        margin-bottom: .5rem;
    }

    &__label {
        margin-bottom: .5rem;
        font-size: .875rem;
        letter-spacing: .025em;
        text-transform: uppercase;
        font-weight: 600;
        color: map-deep-get($colors, "brand", "secondary");
    }

    &__paragraph {
        margin-bottom: 1.5rem;
        flex-grow: 1;
    }

    &:hover {
        text-decoration: none;

        #{$elm}__flex {
            box-shadow: $shadow-hover;
        }
    }
}