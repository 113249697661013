﻿.article {
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
        margin-top: 7.5rem;
    }

    &__block {
        border-radius: $border-radius;
        box-shadow: $shadow-default;
        overflow: hidden;
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    &__image {
        width: 100%;
        height: auto;
    }

    &__content {
        padding: 2rem;

        @include media-breakpoint-up(md) {
            padding: 3rem;
        }
    }

    &__date {
        color: map-deep-get($colors, "brand", "secondary");
        margin-bottom: .5rem;
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__sticky-wrapper {
        flex-grow: 1;
    }

    &__sticky {
        @supports (position: sticky) {
            position: sticky;
            top: 10rem;
        }
    }
}