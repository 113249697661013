﻿.search {
    position: relative;
    overflow: hidden;

    &__keyword {
        color: map-deep-get($colors, "brand", "secondary");
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: -10rem;
        bottom: 0;
        right: 0;
        left: 66.667%;
        background: map-deep-get($colors, "brand", "secondary-lighter");

        @include media-breakpoint-up(md) {
            left: 75%;
        }
    }

    &__results {
        padding-bottom: 3rem;

        @include media-breakpoint-up(md) {
            padding-bottom: 5rem;
        }
    }
}