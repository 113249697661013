﻿.spotlight-home {
    @extend .section-padding;

    &__title {
        color: map-deep-get($colors, "brand", "secondary");
        margin-bottom: 0;
    }

    &__cards {
        margin-top: 2rem;

        @include media-breakpoint-up(md) {
            margin-top: 3rem;
        }
    }
}