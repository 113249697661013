.footer-quicknav {
    padding-top: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;

    &__list {
        margin-bottom: 1.5rem;

        &--twocol {
            column-count: 1;
            break-inside: avoid;

            @include media-breakpoint-up(sm) {
                column-count: 2;
                column-gap: 20px;
            }
        }
    }

    &__title {
        color: map-deep-get($colors, "brand", "secondary");
        margin-bottom: .25rem;
    }

    &__link {
        color: map-deep-get($colors, "brand", "primary");
        display: inline-block;
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            background: url("../images/icons/right-primary.svg");
            height: 1.25rem;
            width: 1.25rem;
            background-size: cover;
            top: .125rem;
            left: -1.5rem;
            transform: translateX(-1.25rem);
            opacity: 0;
            transition: $transition-default;
        }

        &:hover {
            color: map-deep-get($colors, "brand", "primary");
            text-decoration: none;

            &::before {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}