.footer {
    position: relative;
    flex: 1 1 100%;
    z-index: 15;

    &__bottom {
        background: map-deep-get($colors, "brand", "primary");
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media-breakpoint-up(md) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 1rem;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        padding: .125rem;
        margin-bottom: 0.125rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            display: inline-flex;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            align-items: center;
        }
    }

    &__item {
        font-weight: 600;
        color: map-deep-get($colors, "brand", "secondary");
        text-decoration: none;
        padding: .25rem .5rem;
        transition: $transition-default;

        &:hover {
            text-decoration: underline;
            color: map-deep-get($colors, "brand", "secondary");
            opacity: .8;
            padding: 0 .5rem;
        }
    }

    &__reference {
        font-size: 1rem;
        font-weight: 600;
        color: map-deep-get($colors, "white", "default");
        display: block;
        text-align: center;
        width: 100%;
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
            display: inline-block;
            text-align: left;
            margin-top: 0;
        }
    }

    &__webnl {
        transition: $transition-default;
        color: map-deep-get($colors, "white", "default");

        &:hover {
            text-decoration: underline;
            color: map-deep-get($colors, "brand", "secondary");
        }
    }

    &__social {
        padding: .375rem;
        border-radius: $border-radius;
        background: map-deep-get($colors, "brand", "secondary");
        display: inline-flex;
        line-height: 1;
        transition: $transition-default;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        svg {
            height: 1.25rem;
            width: 1.25rem;
            fill: map-deep-get($colors, "white", "default");
        }

        &:hover {
            transform: scale(1.05);
        }
    }
}
