﻿.sub-level-2, .sub-level-3, .sub-level-4, .sub-level-5, .sub-level-6 {
    @include media-breakpoint-up(md) {
        position: absolute;
        left: 100%;
        width: 100%;
        top: 0;
        opacity: 0;
        visibility: hidden;
        padding-bottom: 2rem;
        pointer-events: none;

        &--active {
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
        }

        &__header {
            display: none;
        }

        &__list-item {
            margin: 0;
            text-align: left;
        }

        &__before {
            /* stylelint-disable */
            height: 0 !important;
            /* stylelint-enable */
        }
    }
}

.sub-level-1, .sub-level-2, .sub-level-3, .sub-level-4, .sub-level-5, .sub-level-6 {
    @include media-breakpoint-down(sm) {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        position: fixed;
        bottom: 0;
        background: map-deep-get($colors, "white", "default");
        left: 0;
        top: 2.5rem;
        padding-top: map_get($header-height, "medium--scrolled");
        transition: $transition-default;
        overflow-y: scroll;

        &--active {
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
        }

        &__header {
            margin-bottom: 1.5rem;
        }

        &__container {
            padding-bottom: 2rem;
        }
    }

    &__title {
        margin-bottom: .25rem;
    }

    &__item {
        &::after {
            content: "";
            background-size: cover;
            height: 1.5rem;
            width: 1.5rem;
            transition: $transition-default;
            display: inline-flex;
            opacity: 0;
            margin-left: .75rem;
            position: relative;
            transform: translateX(-1rem);
            vertical-align: bottom;
        }

        &--has-sub {
            opacity: 1;

            &::after {
                opacity: 1;
                background: url("../images/icons/chevron-alt-right.svg");
            }
        }

        @include media-breakpoint-up(md) {
            height: auto;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 600;
            padding: .5rem 0;

            &:hover {
                opacity: 1;

                &::after {
                    opacity: 1;
                    transform: translateY(0);
                    background: url("../images/icons/right-secondary.svg");
                }
            }

            &--has-sub {
                &::after {
                    background: url("../images/icons/chevron-alt-right-white.svg");
                    opacity: .5;
                }

                &:hover {
                    &::after {
                        background: url("../images/icons/right-secondary.svg");
                    }
                }
            }
        }
    }
}
