﻿.product-sidebar {
    position: relative;
    z-index: 10;

    &--mt {
        margin-top: 3rem;
    }

    &__inner {
        position: relative;
        width: 100%;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            position: absolute;
        }
    }
}