﻿.four-o-four {
    height: 100vh;
    display: flex;
    align-items: center;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: -10rem;
        bottom: 0;
        right: 0;
        left: 66.667%;
        background: map-deep-get($colors, "brand", "secondary-lighter");

        @include media-breakpoint-up(md) {
            left: 75%;
        }
    }
}