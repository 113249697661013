﻿.overlay-menu-1, .overlay-menu-2, .overlay-menu-3, .overlay-menu-4, .overlay-menu-5, .overlay-menu-6 {
    &--visible {
        visibility: visible;
        opacity: 1;
        pointer-events: initial;
    }

    &__close, &__back {
        display: none;
    }

    &__title {
        margin-bottom: .25rem;
    }

    &__header {
        margin-bottom: 1.5rem;
    }

    &__columns {
        @include media-breakpoint-down(sm) {
            height: 100%;
            overflow-y: scroll;
        }
    }

    @include media-breakpoint-up(md) {
        &__item {
            color: map-deep-get($colors, "white", "default");
            font-weight: 500;
            opacity: .5;
            padding-right: 4.5rem;

            &::after {
                position: absolute;
                right: 2rem;
            }

            :hover {
                opacity: 1;
            }

            &--active {
                opacity: 1;
            }
        }

        &__back {
            display: none;
        }
    }

    &__sub-direct {
        display: none;
    }
}

.overlay-menu-2, .overlay-menu-3, .overlay-menu-4, .overlay-menu-5, .overlay-menu-6 {
    $elm: &;

    @include media-breakpoint-up(md) {
        &__title {
            display: none;
        }

        &__columns {
            width: 100%;
        }

        &__header {
            margin-bottom: 0;
        }

        &__back {
            position: absolute;
            display: none;
            left: -2rem;
            margin-top: .5rem;
            opacity: .5;
            transition: $transition-default;

            svg {
                fill: map-deep-get($colors, "white", "default");
                height: 1.5rem;
                width: 1.5rem;
                transition: $transition-default;
            }

            &:hover {
                opacity: 1;

                svg {
                    fill: map-deep-get($colors, "brand", "secondary");
                }
            }
        }

        &__direct {
            display: none;
        }

        &__sub-direct {
            color: map-deep-get($colors, "white", "default");
            height: auto;
            display: inline-flex;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 700;
            padding: .5rem 0;
            opacity: .5;

            &::after,
            &__icon {
                content: "";
                background: url("../images/icons/right-white.svg");
                background-size: cover;
                height: 1.5rem;
                width: 1.5rem;
                transition: $transition-default;
                display: inline-flex;
                opacity: 0;
                margin-left: .75rem;
                position: relative;
                transform: translateX(-1rem);
            }

            &:hover {
                color: map-deep-get($colors, "white", "default");
                text-decoration: none;
                opacity: 1;

                &::after {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        &--visible {
            visibility: visible;
            opacity: 1;
            pointer-events: initial;
        }
    }
}

.overlay-menu-1 {
    $elm: &;

    &__sub-direct {
        display: none;
    }

    @include media-breakpoint-up(md) {
        &--rotopbar {
            height: calc(100vh - 3.5rem);
        }

        &__title {
            color: map-deep-get($colors, "white", "default");
            margin-bottom: .25rem;
        }

        &__header {
            margin-bottom: 1.5rem;
        }

        &__columns {
            position: relative;
            padding-top: .125rem;

            @include media-breakpoint-up(md) {
                &--two {
                    transform: translateX(0);
                    /* stylelint-disable */
                    .overlay-menu-2__back {
                        display: block
                    }
                    /* stylelint-enable */
                }

                &--three {
                    transform: translateX(-100%);
                    /* stylelint-disable */
                    .overlay-menu-3__back {
                        display: block
                    }
                    /* stylelint-enable */
                }

                &--four {
                    transform: translateX(-200%);
                    /* stylelint-disable */
                    .overlay-menu-4__back {
                        display: block
                    }
                    /* stylelint-enable */
                }

                &--five {
                    transform: translateX(-300%);
                    /* stylelint-disable */
                    .overlay-menu-5__back {
                        display: block
                    }
                    /* stylelint-enable */
                }
            }

            @include media-breakpoint-up(lg) {
                &--three {
                    transform: translateX(0);
                }

                &--four {
                    transform: translateX(-100%);
                }

                &--five {
                    transform: translateX(-200%);
                }
            }

            @include media-breakpoint-up(xl) {
                &--three {
                    transform: translateX(0);
                }

                &--four {
                    transform: translateX(0);
                }

                &--five {
                    transform: translateX(-100%);
                }
            }

            @include media-breakpoint-up(xxl) {
                &--three {
                    transform: translateX(0);
                }

                &--four {
                    transform: translateX(0);
                }

                &--five {
                    transform: translateX(0);
                }
            }
        }
    }
}
