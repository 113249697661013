﻿.about {
    padding-bottom: 3rem;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        padding-bottom: 5rem;
    }

    &__title {
        color: map-deep-get($colors, "brand", "secondary");
        margin-bottom: .25rem;
    }
}