.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    background: map-deep-get($colors, "white", "default");
    position: relative;
    width: 100%;
    z-index: 99;
    height: map_get($header-height, "small");
    transition: $transition-default;
    padding: 1rem 0;
    overflow: hidden;
    box-shadow: $shadow-default;
    margin-bottom: 0;
    $header: &;

    &::before {
        content: "";
        transition: $transition-default;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        background: map-deep-get($colors, "white", "default");
        z-index: 0;
        pointer-events: none;
    }

    @include media-breakpoint-up(md) {
        box-shadow: none;
        background-color: transparent;
        height: map_get($header-height, "medium");
        overflow: visible;
        padding: 0 1rem;
    }

    &--expanded {
        background: map-deep-get($colors, "white", "default");
        height: calc(100% - 2.5rem);
        position: fixed;
        overflow-y: scroll;
        padding-bottom: 2rem;

        &::before {
            opacity: 1;
            pointer-events: initial;
        }

        &::after {
            content: none;
        }

        #{$header}__call-us {
            top: 3.25rem;
            z-index: 1;
        }
    }

    &--inverted {
        #{$header}__logo {
            @include media-breakpoint-up(md) {
                svg {
                    fill: map-deep-get($colors, "white", "default");

                    @supports (filter:drop-shadow) {
                        filter: drop-shadow($shadow-text);
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: -1;
                height: 10rem;
                background: linear-gradient(180deg, rgba(0, 31, 71, 0.3) 0%, rgba(0, 31, 71, 0) 100%);
            }
        }
    }

    &--scrolled {
        background: map-deep-get($colors, "white", "default");
        margin-top: 0;
        box-shadow: $shadow-default;

        @include media-breakpoint-up(md) {
            background: map-deep-get($colors, "white", "default");
            height: map_get($header-height, "medium--scrolled");

            #{$header}__logo {
                svg {
                    height: 2rem;
                    fill: map-deep-get($colors, "brand", "primary");
                }
            }
        }

        &::after {
            content: none;
        }
    }

    &__logo {
        transition: $transition-default;
        width: auto;
        display: block;
        z-index: 1;

        @include media-breakpoint-down(sm) {
            position: fixed;
        }

        svg {
            width: auto;
            height: 2rem;
            transition: $transition-default;

            @include media-breakpoint-up(md) {
                height: 2.25rem;
                position: relative;
            }

            @include media-breakpoint-up(xl) {
                height: 2.75rem;
            }
        }
    }

    &__call-us {
        top: 3.25rem;
        right: 4.5rem;
        position: fixed;
    }

    &__logo-image {
        height: 100%;
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }

    &__hamburger {
        position: fixed;
        padding: .25rem 1rem;
        right: 1rem;
    }

    &--search {
        margin-bottom: 5rem;
        transition: $transition-default;
        position: relative;
        width: 100%;
    }
}