﻿.hero {
    position: relative;

    &__image {
        border-radius: 0 0 .5rem .5rem;
        box-shadow: $shadow-default;
        overflow: hidden;
        padding-top: 100%;

        @include media-breakpoint-up(xl) {
            padding-top: 66.667%;
        }
    }

    &__col {
        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    &__content {
        background: map-deep-get($colors, "brand", "secondary");
        border-radius: $border-radius;
        box-shadow: $shadow-default;
        padding: 1.5rem;
        margin-top: -3rem;
        margin-bottom: -1.5rem;

        @include media-breakpoint-up(md) {
            padding: 3rem;
            margin-bottom: -3rem;
        }
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
    }

    &__paragraph {
        color: map-deep-get($colors, "white", "default");
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }
    }
}