﻿.contact-form {
    padding: 1rem;
    background: map-deep-get($colors, "brand", "primary");
    box-shadow: $shadow-default;
    border-radius: $border-radius;
    color: map-deep-get($colors, "white", "default");

    @include media-breakpoint-up(sm) {
        padding: 1.5rem 1.5rem 4rem;
    }

    &__alert {
        padding: 0;
        margin-bottom: 3rem;
    }
    /* stylelint-disable */
    h2 {
        margin-bottom: 1.5rem;
    }

    .form {
        overflow: visible;

        &__valueWrapper {
            @include media-breakpoint-up(sm) {
                position: absolute;
            }
        }

        &__button {
            @include media-breakpoint-up(sm) {
                position: absolute;
                bottom: 1.25rem;
                right: 0;
                justify-self: flex-end;
                margin: 0;
            }
        }
    }
    /* stylelint-enable */
}