// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    line-height: 1.5;

    img {
        width: 100%;
        height: auto;
        border-radius: $border-radius;
        box-shadow: $shadow-default;
    }

    a:not(.button):not([href*="download?docID"]):not(.textlink) {
        text-decoration: underline;
        font-weight: 600;
        color: map-deep-get($colors, "brand", "secondary");

        &:hover {
            text-decoration: none;
        }
    }

    a.textlink {
        font-weight: 600;
        color: map-deep-get($colors, "brand", "tertiary");
        text-decoration: underline;

        &::after {
            content: "";
            transition: $transition-default;
            background: url("../images/icons/right.svg");
            background-size: cover;
            height: 1.5rem;
            width: 1.5rem;
            margin-right: 0;
            margin-left: 1rem;
            position: relative;
        }

        &:hover {
            text-decoration: none;

            &::after {
                margin-left: 1.25rem;
                margin-right: -.25rem;
            }
        }
    }

    a[href*="download?docID"] {
        font-weight: 700;
        color: map-deep-get($colors, "brand", "tertiary");
        text-decoration: none;
        display: inline-flex;
        align-items: center;

        &::before {
            content: "";
            transition: $transition-default;
            background: url("../images/icons/download.svg");
            background-size: cover;
            height: 1.5rem;
            width: 1.5rem;
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            text-decoration: none;

            &::before {
                margin-left: 0;
                margin-right: .875rem;
                transform: scale(.9);
            }
        }
    }

    h2, h3, h4, h5, h6 {
        margin-bottom: .5rem;
    }

    h2 {
        font-size: 1.25rem;

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
    }

    h3 {
        font-size: 1.125rem;

        @include media-breakpoint-up(md) {
            font-size: 1.25rem;
        }
    }

    h4, h5, h6 {
        font-size: 1rem;
    }

    h5 {
        font-weight: 600;
    }

    h6 {
        font-weight: 400;
    }

    ul {
        margin: 1.25rem 0;

        li {
            position: relative;
            padding-left: 1.25rem;
            margin-bottom: 0;
            color: map-deep-get($colors, "brand", "primary");

            &::before {
                content: "";
                background: map-deep-get($colors, "brand", "secondary");
                width: 0.375rem;
                height: 0.375rem;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .625rem;
            }
        }

        ul, ol {
            margin: 0;
        }
    }

    ol {
        margin: 1.25rem 0;
        padding: 0;
        list-style: none;
        counter-reset: inst;

        li {
            color: map-deep-get($colors, "brand", "primary");
            counter-increment: inst;
            position: relative;
            padding-left: 1.25rem;

            &::before {
                content: counter(inst);
                display: inline-block;
                position: absolute;
                font-weight: 700;
                font-size: .875rem;
                line-height: 1.5rem;
                width: 1.25rem;
                left: 0;
                color: map-deep-get($colors, "brand", "secondary");
            }
        }

        ul, ol {
            margin: 0;
        }
    }

    .quote {
        font-size: 1.5rem;
        margin-top: 1.5rem;
        margin-bottom: 3rem;
        font-style: italic;
        line-height: 1;
        color: map-deep-get($colors, "brand", "primary");
        font-weight: 200;
        width: 80%;

        @include media-breakpoint-up(md) {
            font-size: 1.75rem;
            width: 75%;
        }

        @include media-breakpoint-up(xl) {
            font-size: 2rem;
        }
    }

    .quote-person {
        font-size: .875rem;
        font-weight: 700;
        color: map-deep-get($colors, "brand", "tertiary");
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    .disclaimer {
        color: map-deep-get($colors, "brand", "primary-medium");
        font-style: italic;
        font-weight: 600;

        &::before {
            content: "";
            background-color: map-deep-get($colors, "brand", "primary-medium");
            height: 1px;
            border-radius: 1px;
            display: block;
            margin-bottom: .5rem;
        }
    }

    table {
        width: 100%;

        tbody {
            tr {
                border-bottom: solid 1px map-deep-get($colors, "brand", "primary-light");
                color: map-deep-get($colors, "brand", "primary");

                td, th {
                    padding: 1rem .5rem;
                    vertical-align: initial;
                }

                th {
                    font-weight: 700;
                }

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
    }

    &--dark {
        h1, h2, h3, h4, h5, h6, p {
            color: map-deep-get($colors, "white", "default");
        }

        .quote {
            color: map-deep-get($colors, "white", "default");
        }

        .button--primary {
            background: map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "brand", "primary");

            svg {
                fill: map-deep-get($colors, "brand", "primary");
            }

            &:hover {
                color: map-deep-get($colors, "brand", "primary");
            }
        }

        ul, ol {
            li {
                color: map-deep-get($colors, "white", "default");
            }
        }

        table {
            tbody {
                tr {
                    border-bottom: solid 1px map-deep-get($colors, "brand", "primary-light");
                    color: map-deep-get($colors, "white", "default");
                }
            }
        }
    }
}

/* stylelint-enable */