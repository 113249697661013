.ratio {
    position: relative;
    width: 100%;

    &__content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: "object-fit: cover;";
    }

    &--r3-1 {
        padding-top: 33.333%;
    }

    &--r21-9 {
        padding-top: 42.857%;
    }

    &--r2-1 {
        padding-top: 50%;
    }

    &--r16-9 {
        padding-top: 56.25%;
    }

    &--r8-5 {
        padding-top: 62.5%;
    }

    &--r3-2 {
        padding-top: 66.66%;
    }

    &--r4-3 {
        padding-top: 75%;
    }

    &--r1-1 {
        padding-top: 100%;
    }
}