﻿.page-header {
    padding-top: 2rem;
    padding-bottom: 3rem;

    @include media-breakpoint-up(md) {
        padding-bottom: 5rem;
    }

    &__breadcrumbs {
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }
    }

    &__textlink {
        margin-bottom: 1.5rem;
    }

    &--products {
        margin-top: 0;

        @include media-breakpoint-up(sm) {
            margin-top: -1.5rem;
        }

        @include media-breakpoint-up(md) {
            margin-top: -3rem;
        }

        @include media-breakpoint-up(lg) {
            margin-top: -4.5rem;
        }
    }

    &--noimg {
        margin-top: 0;

        @include media-breakpoint-up(md) {
            margin-top: 7.5rem;
        }
    }
}
