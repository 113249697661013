﻿.home-cta {
    position: relative;
    right: 0;
    top: 0;
    bottom: 0;
    width: 83.333vw;
    align-self: flex-end;
    margin-top: 3rem;

    @supports (position: sticky) {
        position: sticky;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 5rem;
        width: 33.33vw;
    }

    &__container {
        @include media-breakpoint-up(lg) {
            position: absolute;
            z-index: 10;
            top: 0;
            bottom: 0;
            right: 0;
            width: 33.33vw;
        }
    }

    &__wrapper {
        display: flex;
        height: 100%;
        justify-content: center;
        transform-style: preserve-3d;

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
        }
    }

    &__block {
        background: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");
        padding: 2rem;
        border-radius: .5rem .5rem 0 0;
        box-shadow: $shadow-default;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            padding: 3rem;
            border-radius: .5rem 0 0;
        }
    }

    &__textlink {
        margin-bottom: 1.5rem;
        display: flex;
    }

    &__alert {
        padding: 0 2rem;
        color: map-deep-get($colors, "brand", "primary");

        @include media-breakpoint-up(lg) {
            padding: 0 3rem;
        }
    }
}