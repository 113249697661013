.button {
    padding: 0.75rem 1.5rem;
    min-height: 3rem;
    line-height: 1.5;
    font-size: .875rem;
    border-radius: $border-radius;
    background-color: map-deep-get($colors, "gray", "dark");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    align-items: center;
    margin: 0 .5rem .5rem 0;

    @include media-breakpoint-up(sm) {
        font-size: 1rem;
    }

    &:hover {
        opacity: .8;
        color: map-deep-get($colors, "white", "default");
        text-decoration: none;
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: map-deep-get($colors, "white", "default");
    }

    &--small {
        font-size: .875rem;
        padding: .625rem 1.25rem;
        line-height: 1.25rem;

        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--icon {
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        min-height: 0;
        padding: 0;
        justify-content: center;

        svg {
            margin: 0;
            height: 1.25rem;
            width: 1.25rem;
        }
    }

    &--icon-left {
        svg {
            margin-left: -.25em;
            margin-right: 1em;
        }
    }

    &--icon-right {
        svg {
            margin-right: -.25em;
            margin-left: 1em;
        }
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");
    }

    &--secondary {
        background-color: map-deep-get($colors, "brand", "secondary");
    }

    &--tertiary {
        background-color: map-deep-get($colors, "brand", "tertiary");
    }

    &--black {
        background: map-deep-get($colors, "black", "default");
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }

        &:hover {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &--no-margin {
        margin: 0;
    }
}