.form {
    overflow: hidden;
    transition: $transition-default;
    $form: &;

    &__group-wrapper {
        &--half {
            #{$form}__group:last-of-type {
                margin-bottom: 1.5rem;
            }

            @include media-breakpoint-up(md) {
                display: flex;
                margin: 0 -.5rem;

                #{$form}__group {
                    margin: 0 .5rem 1.5rem;
                    width: 50%;
                }
            }
        }
    }

    &__group {
        position: relative;
        margin-bottom: .75rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        /* stylelint-disable */
        > .form__label {
            display: none;

            &--compact {
                margin-bottom: 0;
                line-height: 2;
                display: inline-block;
                position: absolute;
                top: .5rem;
                left: 1rem;
                pointer-events: none;
                font-weight: 400;
                color: map-deep-get($colors, "gray", "medium");
                transition: $transition-default;
            }
        }
        /* stylelint-enable */
    }

    &__button {
        @extend .button--secondary;
    }

    &__invalid-message {
        color: map-deep-get($colors, "validation", "invalid");
        display: none;
        font-weight: 400;
        font-size: .875rem;
        padding: .5rem 1rem 0;

        /* stylelint-disable */
        &[show] {
            display: block;
        }
        /* stylelint-enable */
    }

    &__label {
        font-weight: 700;
        color: map-deep-get($colors, "brand", "primary");
        display: block;
    }

    &--loading {
        opacity: .5;
    }

    &__label--compact {
        display: none;
    }

    &__input {
        /* stylelint-disable */
        &::placeholder {
            color: transparent;
        }

        &:-ms-input-placeholder {
            color: transparent;
        }
        /* stylelint-enable */

        &:focus,
        &[data-state="filled"] {
            + .form__label--compact {
                font-size: 12px;
                top: .125rem;
            }

            &::placeholder {
                color: map-deep-get($colors, "brand", "primary-light");
            }

            /* stylelint-disable */
            &:-ms-input-placeholder {
                color: map-deep-get($colors, "brand", "primary-light");
            }
            /* stylelint-enable */
        }

        &:-webkit-autofill {
            + .form__label--compact {
                font-size: 12px;
                top: .125rem;
            }

            &::placeholder {
                color: map-deep-get($colors, "brand", "primary-light");
            }
        }

        /* stylelint-disable */
        &[data-state="filled"] {
            + .form__label--compact {
                color: map-deep-get($colors, "brand", "primary-medium");
            }
        }
        /* stylelint-enable */

        &:focus {
            border-color: map-deep-get($colors, "brand", "primary-medium");
            color: map-deep-get($colors, "brand", "primary");

            + .form__label--compact {
                color: map-deep-get($colors, "brand", "primary-medium");
            }
        }

        &--valid[data-state="filled"] {
            border-color: map-deep-get($colors, "validation", "valid");
            color: map-deep-get($colors, "validation", "valid");

            + .form__label--compact {
                color: map-deep-get($colors, "validation", "valid");
            }
        }

        &--invalid[data-state="filled"] {
            border-color: map-deep-get($colors, "validation", "invalid");
            color: map-deep-get($colors, "validation", "invalid");

            + .form__label--compact {
                color: map-deep-get($colors, "validation", "invalid");
            }
        }
    }
}