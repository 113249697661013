.header-wrapper {
    width: 100%;
    position: fixed;
    transition: $transition-default;
    transform: translateY(0);
    z-index: 99;
    top: 0;

    &::before {
        content: none;
    }

    &--inverted {
        &::before {
            content: "";
            display: block;
            position: fixed;
            pointer-events: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 10rem;
            background: linear-gradient(180deg, rgba(0, 31, 71, 0.3) 0%, rgba(0, 31, 71, 0) 100%);
        }
    }

    &--search {
        top: 5rem;
    }

    &--expanded {
        height: calc(100% + 2.5rem);
        transform: translateY(-2.5rem);
    }

    &--rotopbar {
        transform: translateY(-2.5rem);
    }
}