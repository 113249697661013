﻿.product-downloads {
    background: map-deep-get($colors, "brand", "primary");
    border-radius: .5rem .5rem 0 0;
    box-shadow: $shadow-default;
    margin-bottom: -.5rem;
    padding: 1.5rem 2rem;
    position: relative;
    z-index: 11;

    @include media-breakpoint-up(md) {
        padding: 2rem 3rem;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
    }
}