﻿.product-list-cta {
    @include media-breakpoint-up(md) {
        padding-top: 33.333%;
        padding-bottom: 4rem;
        position: relative;
    }

    &__content {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: $border-radius;
        background: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");
        display: flex;
        flex-direction: column;
        padding: 1.5rem;

        @include media-breakpoint-up(md) {
            position: absolute;
        }
    }

    &__title, &__paragraph {
        color: map-deep-get($colors, "white", "default");
    }

    &__title {
        margin-bottom: 1rem;
        flex-grow: 0;

        @include media-breakpoint-up(md) {
            flex-grow: 1;
        }

        @include media-breakpoint-up(lg) {
            flex-grow: 0;
        }
    }

    &__paragraph {
        flex-grow: 1;

        @include media-breakpoint-up(md) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: initial;
        }
    }
}