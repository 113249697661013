﻿.detail-content {
    @extend .section-padding;

    &__image {
        width: 100%;
        height: auto;
        border-radius: $border-radius;
        box-shadow: $shadow-default;
        margin-top: 1.5rem;
        margin-bottom: -1.5rem;

        @include media-breakpoint-up(md) {
            margin-top: -1.5rem;
        }
    }

    &--green {
        background-color: map-deep-get($colors, "brand", "secondary-lighter");
    }

    &--blue {
        background-color: map-deep-get($colors, "brand", "primary");
    }

    &--yellow {
        background-color: map-deep-get($colors, "validation", "warning");
    }
}
