.topbar {
    width: 100%;
    position: relative;
    z-index: 100;
    height: 2.5rem;
    display: flex;
    align-items: center;
    background: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "white", "default");

    &__container {
        padding: 0;
    }

    &__row {
        @include media-breakpoint-up(md) {
            padding: 0 1rem;
        }
    }

    &__list {
        width: 100%;
        line-height: 1;
    }

    &__list-item {
        display: inline-flex;
        margin: 0 .25rem;

        @include media-breakpoint-up(md) {
            margin: 0 .5rem;
        }
    }

    &__item {
        font-size: .875rem;
        line-height: 1;
        padding: .25rem .5rem;
        transition: $transition-default;
        color: map-deep-get($colors, "white", "default");

        &--search {
            svg {
                fill: map-deep-get($colors, "white", "default");
                height: 1.25rem;
                width: 1.25rem;
                transition: $transition-default;
            }

            &:hover {
                text-decoration: none;

                svg {
                    fill: map-deep-get($colors, "brand", "secondary");
                }
            }
        }

        &:hover {
            color: map-deep-get($colors, "brand", "secondary");
        }

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
    }

    &__search {
        display: none;

        @include media-breakpoint-up(sm) {
            display: inline;
            margin-right: .5rem;
        }
    }
}