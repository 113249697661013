﻿.overlay-menu {
    $elm: &;

    &__close {
        display: none;
    }

    @include media-breakpoint-up(md) {
        &__level-1 {
            position: fixed;
            top: 6rem;
            height: calc(100vh - 6rem);
            overflow-y: scroll;

            &--visible {
                visibility: visible;
                opacity: 1;
                pointer-events: initial;
            }
        }

        &__container {
            background: none;
            border-radius: none;
            max-width: 1200px;
            padding: 0;
            box-shadow: none;
        }

        &__columns {
            @include media-breakpoint-up(md) {
                width: 50%;
                transition: $transition-default;
                left: 0;
            }

            @include media-breakpoint-up(lg) {
                width: 33.333%;
            }

            @include media-breakpoint-up(xl) {
                width: 25%;
            }

            @include media-breakpoint-up(xxl) {
                width: 20%;
            }
        }
    }
}

.overlay-menu-0 {
    @include media-breakpoint-up(md) {
        &__before {
            position: fixed;
            background: map-deep-get($colors, "brand", "primary");
            opacity: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100vh;
            display: block;
            pointer-events: none;
            transition: $transition-default;

            &--active {
                opacity: .95;
                pointer-events: initial;
                backface-visibility: visible;
            }

            &--rotopbar {
                height: calc(100vh + 3.5rem);
            }
        }

        &__close {
            position: fixed;
            z-index: 12;
            display: inline-flex;
            width: auto;
            align-items: center;
            left: 2rem;
            top: 3rem;
            font-weight: 700;
            cursor: pointer;
            color: map-deep-get($colors, "brand", "secondary");
            transition: $transition-default;

            svg {
                display: inline-flex;
                height: 1.5rem;
                width: 1.5rem;
                margin-right: .75rem;
                fill: map-deep-get($colors, "brand", "secondary");
                transition: $transition-default;
            }

            &:hover {
                opacity: .8;
            }
        }
    }
}
