.language-select {
    position: relative;
    display: flex;
    flex-direction: column;
    $elm: &;

    &__wrapper {
        position: relative;
        width: auto;
        user-select: none;
    }

    &__trigger {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: 700;
        padding: .5rem 1rem;
        color: map-deep-get($colors, "white", "default");
        cursor: pointer;
        text-transform: uppercase;
    }

    &__options {
        position: absolute;
        display: block;
        top: 100%;
        left: 0;
        padding: .5rem 0;
        margin-top: -.25rem;
        box-shadow: $shadow-default;
        background: map-deep-get($colors, "brand", "secondary");
        border-radius: .25rem;
        transition: all 0.5s;
        transform: translateY(3rem);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        z-index: 100;
    }

    &__arrow {
        margin-left: .5rem;

        svg {
            height: 1rem;
            width: 1rem;
            fill: map-deep-get($colors, "white", "default");
            transition: $transition-default;
        }
    }

    &__option {
        position: relative;
        display: block;
        padding: .25rem 1rem;
        font-size: 1rem;
        font-weight: 700;
        color: map-deep-get($colors, "brand", "primary");
        line-height: 1;
        cursor: pointer;
        text-transform: capitalize;
        text-decoration: none;
        transition: all 0.5s;

        &:hover {
            cursor: pointer;
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;
            opacity: .8;
        }

        &--selected {
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;
        }
    }

    &--open {
        #{$elm}__options {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transform: translateY(0);
        }

        #{$elm}__arrow {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}