.detail-cta {

    background-color: map-deep-get($colors, "validation", "warning");
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;

    h2, h3, h4, h5, h6 {
        color: map-deep-get($colors, "white", "default");
    }

    &__action {
        display: flex;
        justify-content: end;
    }
}
