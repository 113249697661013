﻿.product-cta {
    background: map-deep-get($colors, "brand", "secondary");
    border-radius: $border-radius;
    box-shadow: $shadow-default;
    padding: 2rem 2rem 1.5rem;
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(md) {
        padding: 2.5rem 3rem 2rem;
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
    }

    &__paragraph {
        color: map-deep-get($colors, "white", "default");
    }

    &__textlink {
        display: flex;
        margin-bottom: 1.5rem;
    }

    &__alert {
        margin-top: 1.5rem;
        padding: 0 2rem;
        color: map-deep-get($colors, "brand", "secondary");

        @include media-breakpoint-up(md) {
            padding: 0 3rem;
        }
    }
}