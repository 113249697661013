﻿.process-card {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius;
    background: map-deep-get($colors, "white", "default");
    margin-bottom: 20px;
    box-shadow: $shadow-default;
    $elm: &;

    &__image {
        padding-bottom: 4rem;
    }

    &__content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1.5rem;
        transition: $transition-default;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: $border-radius;
            background: map-deep-get($colors, "brand", "primary");
            opacity: .2;
            transition: $transition-default;
        }
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        text-shadow: $shadow-text;
        position: relative;
        margin-bottom: 0;
        word-break: break-word;
        hyphens: auto;
        hyphenate-limit-chars: 6 3 3;
        hyphenate-limit-lines: 2;
        transition: $transition-default;
    }

    &__paragraph {
        line-height: 0;
        margin-bottom: 0;
        max-height: 0;
        position: relative;
        opacity: 0;
        overflow: hidden;
        transition: $transition-default;
        text-shadow: $shadow-text;
    }

    &__textlink {
        max-height: 0;
        opacity: 0;
        transform: translateX(50px);
        transition: $transition-default;
    }

    &:hover {
        box-shadow: $shadow-hover;

        #{$elm}__content {
            &::before {
                opacity: .85;
            }
        }

        #{$elm}__title, #{$elm}__paragraph {
            color: map-deep-get($colors, "white", "default");
        }

        #{$elm}__textlink {
            opacity: 1;
            margin-top: 1rem;
            max-height: 3rem;
            transform: translateX(0);
        }

        #{$elm}__paragraph {
            opacity: 1;
            margin-top: 1rem;
            max-height: 3rem;
            line-height: 1.5;
        }
    }

    &--blue {
        background: map-deep-get($colors, "brand", "primary");
    }

    &--green {
        background: map-deep-get($colors, "brand", "secondary");
    }

    &--yellow {
        background: map-deep-get($colors, "brand", "tertiary");
    }

    &--noimg {
        #{$elm}__title, #{$elm}__paragraph, #{$elm}__date {
            text-shadow: none;
        }

        #{$elm}__content {
            &::before {
                content: none;
            }
        }

        &#{$elm}--yellow {
            #{$elm}__paragraph {
                color: map-deep-get($colors, "brand", "primary");
            }

            #{$elm}__textlink {
                color: map-deep-get($colors, "brand", "primary");

                svg {
                    fill: map-deep-get($colors, "brand", "primary");
                }

                &:hover {
                    color: map-deep-get($colors, "brand", "primary");

                    svg {
                        fill: map-deep-get($colors, "brand", "primary");
                    }
                }
            }
        }
    }
}