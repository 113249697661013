﻿.topbar-search {
    padding: 1rem;
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    background: map-deep-get($colors, "brand", "primary");
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: $transition-default;

    &--active {
        max-height: 5rem;
        opacity: 1;
        pointer-events: initial;
    }

    &__form {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__input {
        color: map-deep-get($colors, "brand", "primary");
        border-color: map-deep-get($colors, "brand", "primary-light");
        background: map-deep-get($colors, "white", "default");
        padding: .75rem 1rem;

        &:focus {
            border: 1px solid map-deep-get($colors, "brand", "secondary");
        }
    }

    &__submit {
        appearance: none;
        background: none;
        border: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: .5rem;
        height: 1.5rem;
        display: inline-flex;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}