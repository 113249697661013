.nav-block {
    $elm: &;
    position: relative;

    &__wrapper {
        @extend .nav-block;

        @include media-breakpoint-down(sm) {
            overflow: hidden;
        }
    }

    &__is-child {
        left: 100%;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        overflow: visible;
        position: absolute;
        top: 0;
        transition: opacity .3s ease-in-out, transform .3s ease-in-out;
        width: 100%;

        @include media-breakpoint-down(sm) {
            left: 0;
            overflow: hidden;
            padding-bottom: 2rem;

            &--active {
                max-height: 95vh;
                overflow: hidden;
            }
        }

        @include media-breakpoint-up(md) {
            padding-top: 4.625rem;
            padding-left: 2rem;
        }
    }

    /* stylelint-disable */
    // IE11 hack
    @include media-breakpoint-up(md) {
        @media all and (-ms-high-contrast:none)
        {
            *::-ms-backdrop, .nav-block__is-child {
                padding-top: 5rem;
            }
        }
    }
    /* stylelint-enable */

    #{&}[class^="overlay-menu-"][class$="__back"] {
        display: block;
        left: 0;
    }

    &__has-children {
        $child: &;

        /* stylelint-disable */
        .overlay-menu-1__header {
            text-align: center;

            @include media-breakpoint-up(md) {
                text-align: left;
            }

            .overlay-menu-2__sub-direct {
                display: block;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }

        }
        /* stylelint-enable */
    }

    &__col {
        /* stylelint-disable */
        &[data-col="2"] {
            @include media-breakpoint-up(md) {
                transform: translateX(-100%);
            }
            @include media-breakpoint-up(lg) {
                transform: translateX(0%);
            }
        }

        &[data-col="3"] {
            @include media-breakpoint-up(md) {
                transform: translateX(-200%);
            }

            @include media-breakpoint-up(lg) {
                transform: translateX(-100%);
            }
            @include media-breakpoint-up(xl) {
                transform: translateX(0%);
            }
        }

        &[data-col="4"] {
            @include media-breakpoint-up(md) {
                transform: translateX(-300%);
            }
            @include media-breakpoint-up(lg) {
                transform: translateX(-200%);
            }
            @include media-breakpoint-up(xl) {
                transform: translateX(-100%);
            }
        }

        &[data-col="5"] {
            @include media-breakpoint-up(md) {
                transform: translateX(-400%);
            }
            @include media-breakpoint-up(lg) {
                transform: translateX(-300%);
            }
            @include media-breakpoint-up(xl) {
                transform: translateX(-200%);
            }
        }

        &[data-col="6"] {
            @include media-breakpoint-up(sm) {
                transform: translateX(-500%);
            }
            @include media-breakpoint-up(md) {
                transform: translateX(-400%);
            }
            @include media-breakpoint-up(lg) {
                transform: translateX(-300%);
            }
        }
        /* stylelint-enable */
    }
}

.nav-item {
    display: block;
    padding: .5rem 0;
    text-align: center;

    @include media-breakpoint-up(md) {
        padding: 0;
        display: inline-block;
    }

    @include media-breakpoint-up(lg) {
        margin: 0 .5rem;
    }

    &__l0 {
        @extend .nav-item;
    }

    &__active {
        $elm: &;

        &::before {
            //overlay
            content: "";
            position: fixed;
            top: 2.5rem;
            left: 0;
            background: map-deep-get($colors, "white", "default");
            opacity: 1;
            height: 100vh;
            width: 100vw;
            z-index: 1;

            @include media-breakpoint-up(md) {
                background: map-deep-get($colors, "brand", "primary");
                height: calc(100vh - 2.5rem);
                opacity: .95;
            }
        }

        /* stylelint-disable */
        .nav-block__wrapper {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            z-index: 1;
            overflow-y: auto;
            overflow-x: hidden;
            padding-top: 6rem;


            > .nav-block__is-child {
                //First menu in overlay
                max-height: fit-content;
                height: 100%;
                opacity: 1;
                pointer-events: auto;
                overflow-y: auto;
                position: relative;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                margin-left: 0;
                width: 100%;

                @include media-breakpoint-down(sm) {
                    &--active {
                        overflow: hidden;
                    }
                }

                @include media-breakpoint-up(md) {
                    width: 33%;
                    overflow: visible;
                    padding: 0 0 0 3vw;
                }

                @include media-breakpoint-up(lg) {
                    width: 25%;
                    padding: 0 0 0 6vw;
                }

                .nav-item {
                    display: block;
                    text-align: center;
                    margin: 0;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                }

                .nav-link {
                    font-weight: 500;
                    opacity: 1;
                    height: auto;
                    padding: 0 0.5rem;

                    @include media-breakpoint-up(md) {
                        opacity: .5;
                        padding: 0.5rem 4.5rem 0.5rem 0;
                    }
                }

                [class^="overlay-menu-"][class$="__back"] {
                    position: fixed;
                    top: 3.5rem;
                    left: 2rem;
                    height: 2.375rem;
                    width: 2.375rem;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 2.375rem;
                        width: 2.375rem;
                        border-radius: 50%;
                        background: map-deep-get($colors, "brand", "primary");
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0.5rem;
                        left: 0.5rem;
                        transform: rotate(180deg);
                        height: 1.25rem;
                        width: 1.25rem;
                        background-image: url("../images/icons/chevron-right-white.svg");
                        background-size: contain;
                    }

                    @include media-breakpoint-up(md) {
                        left: 0;
                        top: auto;
                        height: auto;
                        width: auto;
                        position: absolute;

                        &::before, &::after {
                            content: none;
                        }
                    }
                }
                .overlay-menu-1__back {
                    @include media-breakpoint-up(md) {
                        display: none !important;
                    }
                }
            }
            /* stylelint-enable */
        }
    }

    &__has-children {
        &--active {
            /* stylelint-disable */
            > .nav-block__is-child {
                max-height: 100%;
                opacity: 1;
                pointer-events: auto;
                overflow-y: auto;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: auto;
                height: 100vh;
                margin-left: 0;
                background: map-deep-get($colors, "white", "default");

                @include media-breakpoint-down(sm) {
                    &--active {
                        max-height: 95vh;
                        overflow: hidden;
                    }
                }

                @include media-breakpoint-up(md) {
                    padding-left: 2rem;
                    background: transparent;
                    width: 100%;
                    height: 100%;
                    right: auto;
                    overflow: visible;
                    position: absolute;
                    left: 100%;
                }
            }
            /* stylelint-enable */
        }
    }
}

.nav-link {
    display: inline-block;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 2.1rem;
    padding: .5rem;
    transition: color #{$transition-default-time / 2} $transition-default-smoothing, opacity #{$transition-default-time / 2} $transition-default-smoothing;

    @include media-breakpoint-up(md) {
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: left;
        height: map_get($header-height, "medium");
        display: inline-flex;
        align-items: center;
    }

    @include media-breakpoint-up(xl) {
        padding: .5rem .75rem;
        font-size: 1.125rem;
    }

    &:hover {
        text-decoration: none;
    }

    &:not(.nav-level__l0) {
        color: map-deep-get($colors, "brand", "primary");

        @include media-breakpoint-up(md) {
            color: map-deep-get($colors, "white", "default");
        }

        &:hover {
            text-decoration: none;
            color: map-deep-get($colors, "brand", "secondary");
        }

        &::after {
            content: "";
            background: url("../images/icons/chevron-alt-right.svg");
            transform: translateX(1rem);
            opacity: 1;
            top: 0.375rem;

            @include media-breakpoint-up(md) {
                content: none;
                background: url("../images/icons/chevron-alt-right-white.svg");
                transform: translateX(-1rem);
                opacity: .5;
                top: auto;
            }
        }
    }

    &::after {
        content: none;
        background: url("../images/icons/chevron-alt-right-white.svg") cover;
        height: 1.5rem;
        width: 1.5rem;
        transition: $transition-default;
        position: absolute;
        right: 0;
        transform: translateX(-1rem);
        opacity: .5;
    }
}

.nav-close {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    position: fixed;
    z-index: 12;
    display: inline-flex;
    width: auto;
    align-items: center;
    left: 2rem;
    top: 3rem;
    font-weight: 700;
    cursor: pointer;
    color: map-deep-get($colors, "brand", "secondary");
    transition: opacity #{$transition-default-time / 2} $transition-default-smoothing;

    svg {
        display: inline-flex;
        height: 1.5rem;
        width: 1.5rem;
        margin-right: .75rem;
        fill: map-deep-get($colors, "brand", "secondary");
        transition: all #{$transition-default-time / 2} $transition-default-smoothing;
    }

    &__active {
        @include media-breakpoint-up(md) {
            max-height: 100%;
            opacity: 1;
            pointer-events: auto;
            overflow: visible;
        }

        &:hover {
            opacity: .8;
        }
    }
}

[class*="nav-level__l"] {
    > .nav-link:not(.nav-level__l0) {
        position: relative;
        width: auto;

        @include media-breakpoint-up(md) {
            width: 100%;
        }

        &::after {
            content: "";
            opacity: 0;
        }

        &:hover {
            opacity: 1;

            &::after {
                @include media-breakpoint-up(md) {
                    opacity: 1;
                    transform: translateX(-0.5rem);
                    background: url("../images/icons/right-secondary.svg");
                }
            }
        }

        &--active {
            color: map-deep-get($colors, "brand", "secondary");
            opacity: 1;

            &::after {
                opacity: 1;
                transform: translateX(-0.5rem);
                background: url("../images/icons/right-secondary.svg");
            }
        }
    }

    &.nav-item__has-children,
    &.nav-item__has-children--active {
        > .nav-link:not(.nav-level__l0) {
            &::after {
                opacity: 1;
            }
        }
    }
}
