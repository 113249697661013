﻿.products-home {
    background: map-deep-get($colors, "brand", "secondary-lighter");
    position: relative;

    &__col {
        @extend .section-padding;
    }

    &__title {
        margin-bottom: 0;
    }

    &__title-wrapper {
        margin-bottom: 1.5rem;
    }
}