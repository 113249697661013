﻿.pagination {
    margin-top: .5rem;
    margin-bottom: 1.75rem;

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
    }

    &__list-item {
        display: inline-flex;
        align-items: center;
    }

    &__list-link {
        display: inline-flex;
        height: 2rem;
        width: 2rem;
        font-weight: 700;
        margin: 0 .125rem;
        font-size: .875rem;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }

        &:hover {
            background: map-deep-get($colors, "brand", "primary-lighter");
            text-decoration: none;
        }

        &--active {
            background: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "white", "default");

            &:hover {
                background: map-deep-get($colors, "brand", "secondary");
                color: map-deep-get($colors, "white", "default");
                opacity: .8;
            }
        }
    }

    &__control-link {
        font-weight: 700;
        color: map-deep-get($colors, "brand", "primary");
        display: inline-flex;
        font-size: .875rem;
        align-items: center;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }

        svg {
            width: 1.25rem;
            height: 1.25rem;
            fill: map-deep-get($colors, "brand", "primary");
            transition: $transition-default;

            @include media-breakpoint-up(md) {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        &:hover {
            opacity: .8;
            color: map-deep-get($colors, "brand", "primary");
            text-decoration: none;
        }

        &--prev {
            @include media-breakpoint-up(md) {
                margin-right: 1.5rem;
            }

            svg {
                margin-right: .75rem;
                margin-left: 0;
                fill: map-deep-get($colors, "brand", "primary");
            }

            &:hover {
                svg {
                    margin-right: 1rem;
                    margin-left: -.25rem;
                }
            }
        }

        &--next {
            @include media-breakpoint-up(md) {
                margin-left: 1.5rem;
            }

            svg {
                margin-left: .75rem;
                margin-right: 0;
            }

            &:hover {
                svg {
                    margin-left: 1rem;
                    margin-right: -.25rem;
                }
            }
        }

        &--inactive {
            pointer-events: none;
            opacity: .3;
        }
    }
}