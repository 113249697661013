﻿.search-form {
    &__form {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__title {
        margin-bottom: .5rem;
    }

    &__input {
        color: map-deep-get($colors, "brand", "primary");
        border-color: map-deep-get($colors, "brand", "primary-light");
        background: map-deep-get($colors, "white", "default");
        padding: .75rem 1rem;

        &:focus {
            border: 1px solid map-deep-get($colors, "brand", "secondary");
        }
    }

    &__submit {
        appearance: none;
        border: none;
        position: absolute;
        right: .5rem;
        height: 1.5rem;
        display: inline-flex;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    &__submit-page {
        appearance: none;
        border: none;
        position: absolute;
        display: inline-flex;
        height: 100%;
        right: 0;
        border-radius: $border-radius;
        padding: .5rem 1rem;
        background: map-deep-get($colors, "brand", "secondary");
        color: map-deep-get($colors, "white", "default");
        font-weight: 700;

        svg {
            fill: map-deep-get($colors, "white", "default");
            margin-left: .75rem;
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}
/* stylelint-disable */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
}
/* stylelint-enable */