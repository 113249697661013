﻿.sub-level-1 {
    @include media-breakpoint-up(md) {
        width: 100%;
        padding: 1rem 2rem;
    }

    &__before {
        @include media-breakpoint-up(md) {
            position: fixed;
            background: map-deep-get($colors, "brand", "primary");
            opacity: .25;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100vh;
            display: block;
            pointer-events: none;

            &--rotopbar {
                height: calc(100vh + 2.5rem);
            }
        }
    }

    &__container {
        @extend .container-fluid;
        position: relative;

        @include media-breakpoint-up(md) {
            padding: 1.5rem;
            background: map-deep-get($colors, "white", "default");
            border-radius: $border-radius;
            text-align: left;
            box-shadow: $shadow-default;
        }

        @include media-breakpoint-up(lg) {
            padding: 2rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 3rem;
        }
    }

    &__columns {
        @include media-breakpoint-up(md) {
            column-count: 2;
            column-fill: balance;
        }

        @include media-breakpoint-up(lg) {
            column-count: 3;
        }

        @include media-breakpoint-up(xl) {
            column-count: 4;
        }

        @include media-breakpoint-up(xxl) {
            column-count: 5;
        }
    }

    &__header {
        @include media-breakpoint-up(md) {
            display: block;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
            border-bottom: solid 1px map-deep-get($colors, "brand", "primary-light");
        }
    }

    &__list-item {
        @include media-breakpoint-up(md) {
            text-align: left;
            margin: 0;
        }
    }
}